/* eslint-disable multiline-ternary */
import { cx } from 'class-variance-authority'
import Link from 'next/link'

type TUploadLabel = {
  isError?: boolean
  title: string
  subtitle?: string
  fileURL?: string
  hideUploadedDocs?: boolean
  isDisabled?: boolean
}
const UploadLabel = ({ isError, title, subtitle, fileURL = '', hideUploadedDocs, isDisabled }: TUploadLabel) => {
  const url = fileURL?.includes('pdf-viewer?') ? `${fileURL}&open_new_tab=true` : fileURL
  return (
    <>
      <p
        dangerouslySetInnerHTML={{ __html: title }}
        className={cx(
          'mb-1 line-clamp-1 break-all text-sm font-semibold',
          isError ? 'text-error500' : 'text-tertiary500',
        )}
      />
      {!!subtitle && <span className="text-xs text-tertiary300">{subtitle}</span>}
      {fileURL && !hideUploadedDocs && (
        <Link
          className={cx(
            'mt-1 w-fit text-sm font-semibold text-secondary500 hover:underline',
            isDisabled ? 'pointer-events-none' : 'cursor-pointer',
          )}
          href={isDisabled ? '#' : url}
          target={isDisabled ? undefined : '_blank'}
          referrerPolicy="no-referrer"
          aria-disabled={isDisabled}
        >
          Lihat Dokumen
        </Link>
      )}
    </>
  )
}

export default UploadLabel
